<template>
  <page-title />
</template>

<script>
import PageTitle from '@/components/PageTitle'

export default {
  name: 'Error403',
  components: { PageTitle }
}
</script>
